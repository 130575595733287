@tailwind base;
@tailwind components;
@tailwind utilities;

.header-link {
  @apply text-base first:font-pixeboy font-montserrat font-bold border-transparent border-b-4 text-white h-full hover:text-linkColor transition-all duration-300 hover:border-b-2 hover:border-linkColor  box-border;
}

.mega-menu-link {
  @apply text-base font-montserrat font-bold border-transparent border-b-4 text-linkColor hover:text-white transition-all duration-300 hover:border-b-2 hover:border-linkColor  box-border;
}

.hoverable {
  @apply static;
}

.mega-menu {
  @apply hidden left-0 absolute text-left w-full;
}

.hoverable a.megaMenuA {
  @apply relative top-[0px] after:content-['\25BC'] after:text-2xl after:-top-[6px] after:absolute after:-right-4 after:max-xl:hidden;
}

.hoverable:hover .mega-menu {
  @apply block max-xl:hidden;
}

.protto__link {
  @apply bg-prottoBG hover:bg-prottoHover transition-all duration-300 py-5 px-20 font-montserrat flex justify-center text-white rounded-lg max-md:text-sm max-lg:px-0 max-lg:py-3;
}

.protto__span {
  @apply text-linkColor ml-1 font-bold flex transition-all duration-300;
}

.protto__link:hover .protto__span {
  @apply text-white;
}

.schedule__btn {
  @apply bg-linkColor px-2 max-xl:w-40 text-white font-montserrat rounded-sm font-bold border-b-4 border-transparent h-full transition-all duration-300 hover:border-linkColor hover:bg-white hover:text-linkColor;
}

.insafter {
  @apply relative after:content-['\25BC'] after:text-2xl after:-top-[4px] after:absolute after:-right-4 after:max-xl:hidden;
}

.accordion__content {
  @apply max-h-[0em] bg-white transition-all duration-[0.4s] ease-[cubic-bezier(0.865,0.14,0.095,0.87)];
}

input[name="panel"]:checked ~ .accordion__content {
  @apply max-h-[50em];
}

.Label__BTN {
  @apply cursor-pointer bg-linkColor/40 transition-all duration-300 h-[61px] hover:bg-linkColor/70 after:content-[">"] after:right-[10px] after:top-[10px] after:absolute after:text-[26px] max-lg:after:text-[15px];
}

input:checked + .Label__BTN {
  @apply after:content-[">"] after:rotate-90;
}

.accordionDiv {
  @apply border-2 border-linkColor;
}

.protto__bgimg {
  @apply bg-white max-lg:bg-none bg-[url(./assets/images/background1531-300x220.png)] bg-[bottom_right] bg-no-repeat bg-[40%_auto];
}

.testimonial__bg {
  @apply bg-[url(./assets/images/testimonials-bg.jpg)];
}

.help__bg {
  @apply bg-[url(./assets/images/Captures.png)];
}

.helpBG__before {
  @apply before:content-[''] before:bg-[url(./assets/images/Mask-group.png)] before:absolute before:left-0 before:w-1/6 before:-top-24 before:bg-no-repeat before:h-full;
}

#scroll {
  @apply fixed cursor-pointer w-[50px] h-[50px] bg-prottoHover indent-[-9999px] hidden rounded-[60px] right-2.5 bottom-2.5 hover:opacity-100;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
}

.digital__bg {
  @apply bg-[url(./assets/images/photo1658743352.jpeg)];
}

.team__bg {
  @apply bg-[url(./assets/images/The-Team.webp)];
}

.meet__bg {
  @apply bg-[url(./assets/images/footer-cta.webp)] before:content-[''] before:bg-[url(./assets/images/Mask-group.png)] before:absolute before:left-0 before:w-1/6 before:-top-24 before:bg-no-repeat before:h-full;
}

.about__bg {
  @apply bg-[url(./assets/images/The-Firm.webp)];
}

.maykl__bg {
  @apply bg-[url(./assets/images/H6A0966-scaled.jpg)];
}

.individuals__bg {
  @apply bg-[url(./assets/images/The-Firm-Subpages.webp)];
}

@layer base {
  @font-face {
    font-family: "Pixeboy";
    src: url("./assets/fonts/Pixeboy-z8XGD.ttf");
  }

  @font-face {
    font-family: "Montserrat";
    src: url("./assets/fonts/montserrat/Montserrat-Italic-VariableFont_wght.ttf");
  }
}

body {
  @apply overflow-x-hidden;
}
